// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiHit } from '../../../../../Api/apiRequest'
import { setStatus, STATUSES } from '../../../../../redux/authentication'

//get customer service data
export const getCustHandymanService = createAsyncThunk(
  'services/customer/getCustHandymanService',
  async (data, { dispatch }) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

//get customer service data || popular place
export const getCustHandymanNearBy = createAsyncThunk(
  'services/customer/getCustHandymanNearBy',
  async (data, { dispatch }) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

//get customer Handyman explore
export const getCustHandymanDetails = createAsyncThunk(
  'services/customer/getCustHandymanDetails',
  async (data, { dispatch }) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data[0]
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

export const customerHandymanSlice = createSlice({
  name: 'customerHandyman',
  initialState: {
    custPopHandymanData: [],
    custNearbyHandyman: [],
    custHandymanDetailsData: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustHandymanService.fulfilled, (state, action) => {
        state.custPopHandymanData = action.payload
      })
      .addCase(getCustHandymanNearBy.fulfilled, (state, action) => {
        state.custNearbyHandyman = action.payload
      })
      .addCase(getCustHandymanDetails.fulfilled, (state, action) => {
        state.custHandymanDetailsData = action.payload
      })
  }
})

export const {} = customerHandymanSlice.actions
export default customerHandymanSlice.reducer
