// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiHit, apiHitSecond } from '../../../../../Api/apiRequest'
import { setStatus, STATUSES } from '../../../../../redux/authentication'
import { checkAddUpdate, resetServiceId } from '../../../../services/business/store'

//get_services
export const tableBservice = createAsyncThunk('services/business/table-service', async (data, { dispatch }) => {
  const body = new FormData()
  body.append('type', 'get_data')
  body.append('table_name', 'services')
  Object.keys(data).forEach((key) => {
    body.append(key, data[key])
  })
  const response = await apiHit(body, dispatch)
    .then(async (res) => {
      console.log(res)
      if (res.data.length !== 0) {
        dispatch(setStatus(STATUSES.IDLE))
        return [res.data.data[0]]
      } else {
        dispatch(setStatus(STATUSES.IDLE))
        return ''
      }
    })
    .catch((err) => {
      dispatch(setStatus(STATUSES.ERROR))
      return err
    })
  return response
})

//get_service_items
export const tableBserviceItems = createAsyncThunk(
  'services/business/table-serviceitems',
  async (obj, { dispatch }) => {
    const body = new FormData()
    body.append('type', 'get_data')
    body.append('table_name', 'service_items')
    Object.keys(obj).forEach((key) => {
      body.append(key, obj[key])
    })
    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data[0]
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

// ** delete_services
export const DeleteBusinessService = createAsyncThunk(
  'services/business/DeleteBusinessService',
  async (data, { dispatch }) => {
    const body = new FormData()
    body.append('type', 'delete_data')
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })
    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        let obj = {
          user_id: data.user_id
        }
        if (res.data.result === true) {
          if (data.table_name === 'services') {
            dispatch(tableBservice(obj))
            dispatch(checkAddUpdate('Create'))
            dispatch(resetServiceId())
          } else if (data.table_name === 'service_items') {
            dispatch(tableBserviceItems(obj))
          }
          dispatch(setStatus(STATUSES.IDLE))
          return await res.data.result
        } else {
          dispatch(setStatus(STATUSES.ERROR))
          return false
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

// ** get Orders || params userData
export const getBusinessAllOrders = createAsyncThunk(
  'services/business/getBusinessAllOrders',
  async (obj, { dispatch }) => {
    const body = new FormData()
    body.append('type', 'get_data')
    body.append('table_name', 'orders')
    Object.keys(obj).forEach((key) => {
      body.append(key, obj[key])
    })
    try {
      // dispatch(setManageOrderLoader(true))
      const res = await apiHitSecond(body)
      console.log(res)
      if (res.data.length !== 0) {
        return res.data.data
      } else {
        dispatch(setStatus(STATUSES.IDLE))
        return res.data[0]
      }
    } catch (err) {
      dispatch(setStatus(STATUSES.ERROR))
      return err
    } finally {
      dispatch(setManageOrderLoader(false))
    }
  }
)

// ** update Orders status || params id,status
export const updateBusinessOrderStatus = createAsyncThunk(
  'services/business/updateBusinessOrderStatus',
  async (obj, { dispatch }) => {
    const body = new FormData()
    body.append('type', 'update_data')
    body.append('table_name', 'orders')
    Object.keys(obj).forEach((key) => {
      body.append(key, obj[key])
    })
    try {
      dispatch(setUpdateStatusLoader(true))
      const response = await apiHit(body, dispatch)
      console.log(response)
      //   if (res.data.length !== 0) {
      //     dispatch(setStatus(STATUSES.IDLE))
      //     return res.data.data
      //   } else {
      //     dispatch(setStatus(STATUSES.IDLE))
      //     return res.data[0]
      //   }
    } catch (err) {
      dispatch(setStatus(STATUSES.ERROR))
      return err
    } finally {
      dispatch(setUpdateStatusLoader(false))
    }
    return response
  }
)

export const businessTableSlice = createSlice({
  name: 'businessTable',
  initialState: {
    bServiceTableData: [],
    bServiceItemTableData: [],
    tableResult: false,
    businessAllOrders: [],
    updateMessage: '',
    manageOrderLoader: false,
    updateStatusLoader: false
  },
  reducers: {
    resetApiResult: (state) => {
      state.tableResult = false
    },
    setManageOrderLoader: (state, action) => {
      state.manageOrderLoader = action.payload
    },
    setUpdateStatusLoader: (state, action) => {
      state.updateStatusLoader = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(tableBserviceItems.fulfilled, (state, action) => {
        state.bServiceItemTableData = action.payload
      })
      .addCase(tableBservice.fulfilled, (state, action) => {
        state.bServiceTableData = action.payload
      })
      .addCase(DeleteBusinessService.fulfilled, (state, action) => {
        state.tableResult = action.payload
      })
      .addCase(getBusinessAllOrders.fulfilled, (state, action) => {
        state.businessAllOrders = action.payload
      })
      .addCase(updateBusinessOrderStatus.fulfilled, (state, action) => {
        state.updateMessage = action.payload
      })
  }
})

export const { resetApiResult, setManageOrderLoader, setUpdateStatusLoader } = businessTableSlice.actions
export default businessTableSlice.reducer
