// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiHit } from '../../../../../Api/apiRequest'
import { setStatus, STATUSES } from '../../../../../redux/authentication'

//get customer service data
export const getCustUniversityService = createAsyncThunk(
  'services/customer/getCustUniversityService',
  async (data, { dispatch }) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

//get customer service data || new arrival
export const getCustUniversityNewArrival = createAsyncThunk(
  'services/customer/getCustUniversityNewArrival',
  async (data, { dispatch }) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

//get customer cuntries data
export const getCustCountryService = createAsyncThunk(
  'services/customer/getCustCountryService',
  async (data, { dispatch }) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

// ** get near by University
export const getCustUniversityNearBy = createAsyncThunk(
  'services/customer/getCustUniversityNearBy',
  async (data, { dispatch }) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

//get customer university details
export const getCustUniversityDetails = createAsyncThunk(
  'services/customer/getCustUniversityDetails',
  async (data, { dispatch }) => {
    console.log(data)
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data[0]
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

export const customerUniversitySlice = createSlice({
  name: 'customerUniversity',
  initialState: {
    custUniversityData: [],
    custUniversityNAData: [],
    custCountriesData: [],
    custUniversityDetailsData: [],
    custNearByUniversityData: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustUniversityService.fulfilled, (state, action) => {
        state.custUniversityData = action.payload
      })
      .addCase(getCustUniversityNewArrival.fulfilled, (state, action) => {
        state.custUniversityNAData = action.payload
      })
      .addCase(getCustCountryService.fulfilled, (state, action) => {
        state.custCountriesData = action.payload
      })
      .addCase(getCustUniversityDetails.fulfilled, (state, action) => {
        state.custUniversityDetailsData = action.payload
      })
      .addCase(getCustUniversityNearBy.fulfilled, (state, action) => {
        state.custNearByUniversityData = action.payload
      })
  }
})

// export const {} = customerUniversitySlice.actions
export default customerUniversitySlice.reducer
