// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { addOrder } from './actions'

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: {
    message: ''
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addOrder.fulfilled, (state, action) => {
      state.message = action.payload
    })
  }
})

export const {} = checkoutSlice.actions
export default checkoutSlice.reducer
