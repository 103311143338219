// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiHit, body } from '../../../../Api/apiRequest'
import { setStatus, STATUSES } from '../../../../redux/authentication'

// ** handle_service_request
export const handleServicesRequest = createAsyncThunk(
  ' services/business/manage-service-request',
  async (data, { dispatch, getState }) => {
    console.log(getState().businessService.serviceAddUpdate)
    if (getState().businessService.serviceAddUpdate === 'Update') {
      let obj = { ...data, id: getState().businessService.serviceId }
      dispatch(updateBuisnessService(obj))
    } else {
      dispatch(addBuisnessService(data))
    }
  }
)

// ** handle_service_items_request
export const handleServiceItemsRequest = createAsyncThunk(
  ' services/business/manage-serviceItems-request',
  async (data, { dispatch }) => {
    const service_item_id = localStorage.getItem('service_item_id')
    if (service_item_id !== null) {
      let obj = {
        id: service_item_id,
        ...data
      }
      console.log(obj)
      dispatch(updateBserviceItems(obj))
    } else {
      console.log(data)
      dispatch(addBserviceItems(data))
    }
  }
)

// ** add_services
export const addBuisnessService = createAsyncThunk('services/business/add', async (data, { dispatch }) => {
  console.log(data)
  const body = new FormData()
  body.append('type', 'add_data')
  body.append('table_name', 'services')
  Object.keys(data).forEach((key) => {
    body.append(key, data[key])
  })
  const response = await apiHit(body, dispatch)
    .then(async (res) => {
      console.log(res)
      if (res.data.result === true) {
        dispatch(setStatus(STATUSES.IDLE))
        dispatch(addServiceResult(res.data.result))
        return res.data.id
      } else {
        dispatch(setStatus(STATUSES.ERROR))
      }
    })
    .catch((err) => {
      dispatch(setStatus(STATUSES.ERROR))
      return err
    })
  return response
})

//add_service_items  ** multi services
export const addBserviceItems = createAsyncThunk('services/business/addItem', async (data, { dispatch }) => {
  const formData = new FormData()
  formData.append('type', 'add_data')
  formData.append('table_name', 'service_items')
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key])
  })

  await apiHit(formData, dispatch)
    .then(async (res) => {
      console.log(res)
      if (res.data.result === true) {
        dispatch(editServiceItemId(res.data.id))
        dispatch(addServiceItemsResult(res.data.result))
        dispatch(setStatus(STATUSES.IDLE))
      } else {
        dispatch(setStatus(STATUSES.ERROR))
      }
    })
    .catch((err) => {
      dispatch(setStatus(STATUSES.ERROR))
      return err
    })
})

// ** update_services
export const updateBuisnessService = createAsyncThunk('services/business/update', async (data, { dispatch }) => {
  console.log(data)
  body.append('type', 'update_data')
  body.append('table_name', 'services')
  Object.keys(data).forEach((key) => {
    body.append(key, data[key])
  })
  const response = await apiHit(body, dispatch)
    .then(async (res) => {
      console.log(res)
      if (res.data.result === true) {
        dispatch(setStatus(STATUSES.IDLE))
        dispatch(addServiceResult(res.data.result))
        return res.data.message
      } else {
        dispatch(setStatus(STATUSES.ERROR))
      }
    })
    .catch((err) => {
      dispatch(setStatus(STATUSES.ERROR))
      return err
    })
  return response
})
// ** update_services
export const updateBserviceItems = createAsyncThunk(
  'services/business/update-service-items',
  async (data, { dispatch }) => {
    const body = new FormData()
    body.append('type', 'update_data')
    body.append('table_name', 'service_items')
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })
    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.result === true) {
          dispatch(setStatus(STATUSES.IDLE))
          dispatch(addServiceItemsResult(res.data.result))
          return await res.data.message
        } else {
          dispatch(setStatus(STATUSES.ERROR))
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

//get_services
export const getBuisnessService = createAsyncThunk('services/business/get', async (params, { dispatch }) => {
  const body = new FormData()
  const { obj, btnStatus } = params
  let data = obj
  console.log(data)
  body.append('type', 'get_data')
  body.append('table_name', 'services')
  Object.keys(data).forEach((key) => {
    body.append(key, data[key])
  })

  const response = await apiHit(body, dispatch)
    .then(async (res) => {
      console.log(res)
      if (res.data.length === 0) {
        if (btnStatus === 'btnStatus') {
          console.log(btnStatus)
          // ** btnStatus check pass only want to test there no service add before
          dispatch(setStatus(STATUSES.IDLE))
          // ** dashboard status set as add service if res.data empty
          dispatch(checkAddUpdate('Create'))
          return res.data
        } else {
          // ** if no single service add before add first
          await dispatch(addBuisnessService(data))
        }
      } else {
        console.log(res.data.data[0])
        dispatch(checkAddUpdate('Update')) // ** if service add before from button set update
        dispatch(setStatus(STATUSES.IDLE))
        dispatch(getServicesData(await res.data.data[0]))
        return await res.data.data[0].id
      }
    })
    .catch((err) => {
      dispatch(setStatus(STATUSES.ERROR))
      return err
    })
  return response
})

//get_service_items
export const getBserviceItems = createAsyncThunk('services/business/get-serviceitems', async (params, { dispatch }) => {
  const { obj, allItems } = params
  body.append('type', 'get_data')
  body.append('table_name', 'service_items')
  Object.keys(obj).forEach((key) => {
    body.append(key, obj[key])
  })
  const response = await apiHit(body, dispatch)
    .then(async (res) => {
      console.log(res)
      if (res.data.length === 0) {
        dispatch(setStatus(STATUSES.IDLE))
        return res.data[0]
        // ** if no single service add before add first
      } else {
        dispatch(setStatus(STATUSES.IDLE))
        if (allItems === 'allServiceItems') {
          return res.data.data
        } else {
          console.log(res.data.data[0])
          return res.data.data[0]
        }
      }
    })
    .catch((err) => {
      dispatch(setStatus(STATUSES.ERROR))
      return err
    })
  return response
})

const initialItemId = () => {
  const itemId = window.localStorage.getItem('service_item_id')
  return itemId
}

export const businessServiceSlice = createSlice({
  name: 'businessService',
  initialState: {
    serviceId: '',
    serviceItemId: initialItemId(),
    serviceAddUpdate: '',
    message: '',
    serviceData: [],
    serviceItemData: [],
    gallery: [],
    itemColors: [],
    roomDeals: [],
    serviceResult: false,
    serviceItemsResult: false
  },
  reducers: {
    imageGallery: (state, action) => {
      state.gallery = action.payload
    },
    checkedItemColor: (state, action) => {
      state.itemColors = action.payload
    },
    getServicesData: (state, action) => {
      state.serviceData = action.payload
    },
    checkAddUpdate: (state, action) => {
      state.serviceAddUpdate = action.payload
    },
    roomDealsList: (state, action) => {
      state.roomDeals = action.payload
    },
    addServiceResult: (state, action) => {
      state.serviceResult = action.payload
    },
    resetApiResult: (state) => {
      state.serviceResult = false
      state.serviceItemsResult = false
    },
    addServiceItemsResult: (state, action) => {
      state.serviceItemsResult = action.payload
    },
    editServiceItemId: (state, action) => {
      console.log(action.payload)
      state.serviceItemId = action.payload
      localStorage.setItem('service_item_id', action.payload)
    },
    resetServiceItemData: (state) => {
      state.serviceItemData = []
    },
    removeServiceItemId: (state) => {
      state.serviceItemId = null
      localStorage.removeItem('service_item_id')
    },
    resetServiceId: (state) => {
      state.serviceId = ''
      state.serviceData = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBuisnessService.fulfilled, (state, action) => {
        state.serviceId = action.payload
      })
      .addCase(updateBuisnessService.fulfilled, (state, action) => {
        state.message = action.payload
      })
      .addCase(addBuisnessService.fulfilled, (state, action) => {
        state.serviceId = action.payload
      })
      .addCase(getBserviceItems.fulfilled, (state, action) => {
        state.serviceItemData = action.payload
      })
  }
})

export const {
  imageGallery,
  getServicesData,
  checkAddUpdate,
  roomDealsList,
  addServiceResult,
  resetApiResult,
  addServiceItemsResult,
  editServiceItemId,
  removeServiceItemId,
  resetServiceItemData,
  resetServiceId
} = businessServiceSlice.actions
export default businessServiceSlice.reducer
