// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'
import { serviceRole } from '../@fake-db/countiresPicker/serviceData'
import { apiHit } from '../Api/apiRequest'

const config = useJwt.jwtConfig

export const STATUSES = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading'
})

// **  user login
export const userLogin = createAsyncThunk('/login', async (data, { dispatch }) => {
  const body = new FormData()
  body.append('type', 'login')
  Object.keys(data).forEach((key) => {
    body.append(key, data[key])
  })

  const response = await apiHit(body, dispatch)
    .then(async (res) => {
      console.log(res)
      if (res.data.result === true) {
        let user_data = {
          user_id: res.data.user_id,
          name: res.data.name,
          image: res.data.image,
          user_type: res.data.user_type,
          business_type: res.data.business_type,
          business_role: serviceRole[res.data.business_type]
        }
        console.log(res.data.result)
        dispatch(handleLogin(user_data))
        dispatch(handleUserType(res.data.user_type))
        dispatch(setStatus(STATUSES.IDLE))
        return await res.data.result
      } else {
        dispatch(setStatus(STATUSES.ERROR))
        dispatch(resultMessage(res.data.message))
        return false
      }
    })
    .catch((err) => {
      dispatch(setStatus(STATUSES.ERROR))
      return err
    })
  return response
})

// **  user signup
export const userSignup = createAsyncThunk('/register/account', async (data, { dispatch }) => {
  const body = new FormData()
  body.append('type', 'register')
  Object.keys(data).forEach((key) => {
    body.append(key, data[key])
  })
  console.log(data)

  serviceRole[data.business_type] === 'single'
  const response = await apiHit(body, dispatch)
    .then(async (res) => {
      console.log(res)
      if (res.data.result === true) {
        let user_data = {
          user_id: res.data.user_id,
          name: JSON.parse(localStorage.getItem('userData')),
          user_type: data.user_type,
          business_type: data.business_type,
          business_role: serviceRole[data.business_type]
        }
        dispatch(handleLogin(user_data))
        dispatch(handleUserType(data.user_type))
        dispatch(setStatus(STATUSES.IDLE))
        return await res.data.result
      } else {
        dispatch(setStatus(STATUSES.ERROR))
        return false
      }
    })
    .catch((err) => {
      dispatch(setStatus(STATUSES.ERROR))
      return err
    })
  return response
})

// ** register phone number
export const registerPhone = createAsyncThunk('/register/phone', async (data, { dispatch }) => {
  const body = new FormData()
  body.append('type', 'send_otp')
  Object.keys(data).forEach((key) => {
    body.append(key, data[key])
  })

  const response = await apiHit(body, dispatch)
    .then(async (res) => {
      console.log(res)
      if (res.data.result === true) {
        // ** if phone registered already
        if (res.data.user_id !== false) {
          let user_data = {
            user_id: res.data.user_id,
            name: res.data.name,
            image: res.data.image,
            user_type: res.data.user_type,
            business_type: res.data.business_type,
            business_role: serviceRole[res.data.business_type]
          }
          localStorage.setItem('tempUserData', JSON.stringify(user_data))
          dispatch(handleUserType(res.data.user_type))
          // dispatch(handleLogin(user_data))
        }
        dispatch(
          handlePhoneReg({
            phone: data.phone,
            otp_Code: res.data.code
          })
        )
        dispatch(setStatus(STATUSES.IDLE))
        return res.data
      } else {
        dispatch(setStatus(STATUSES.ERROR))
        return false
      }
    })
    .catch((err) => {
      dispatch(setStatus(STATUSES.ERROR))
      return err
    })
  return response
})

const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser(),
    addUser: [],
    userType: '',
    status: STATUSES.IDLE,
    otpCode: '',
    apiResult: false,
    apiResponse: [],
    message: ''
  },
  reducers: {
    handlePhoneReg: (state, action) => {
      state.otpCode = action.payload
      localStorage.setItem('otp_Code', state.otpCode.otp_Code)
      localStorage.setItem('phone', state.otpCode.phone)
    },
    handleLogin: (state, action) => {
      state.userData = action.payload
      // state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      // state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      localStorage.setItem('userData', JSON.stringify(action.payload))
      // localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload.accessToken))
      // localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(action.payload.refreshToken))
    },
    handleLogout: (state) => {
      state.userData = {}
      // state[config.storageTokenKeyName] = null
      // state[config.storageRefreshTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('userType')
      // localStorage.removeItem(config.storageTokenKeyName)
      // localStorage.removeItem(config.storageRefreshTokenKeyName)
    },
    handleUserType: (state, action) => {
      state.userType = action.payload
      localStorage.setItem('userType', action.payload)
    },
    setStatus: (state, action) => {
      state.status = action.payload
    },
    resetApiResult: (state) => {
      state.apiResult = false
      state.message = ''
    },
    resultMessage: (state, action) => {
      state.message = action.payload
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(registerPhone.fulfilled, (state, action) => {
        state.apiResponse = action.payload
      })
      .addCase(userSignup.fulfilled, (state, action) => {
        state.apiResult = action.payload
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.apiResult = action.payload
      })
  }
})

export const { handleLogin, handleLogout, handleUserType, setStatus, handlePhoneReg, resetApiResult, resultMessage } =
  authSlice.actions

export default authSlice.reducer
