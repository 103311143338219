// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiHit } from '../../../../../Api/apiRequest'
import { setStatus, STATUSES } from '../../../../../redux/authentication'

//get customer popular doctor data
export const getCustDoctorsPop = createAsyncThunk('services/customer/getCustDoctorsPop', async (data, { dispatch }) => {
  const body = new FormData()
  Object.keys(data).forEach((key) => {
    body.append(key, data[key])
  })

  const response = await apiHit(body, dispatch)
    .then(async (res) => {
      console.log(res)
      if (res.data.length !== 0) {
        dispatch(setStatus(STATUSES.IDLE))
        return res.data.data
      } else {
        dispatch(setStatus(STATUSES.IDLE))
        return res.data
      }
    })
    .catch((err) => {
      dispatch(setStatus(STATUSES.ERROR))
      return err
    })
  return response
})

//get customer near by doctors
export const getCustDoctorNearBy = createAsyncThunk(
  'services/customer/getCustDoctorNearBy',
  async (data, { dispatch }) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

//get customer doctors explore
export const getCustDoctorsDetails = createAsyncThunk(
  'services/customer/getCustDoctorsDetails',
  async (data, { dispatch }) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data[0]
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

export const customerDoctorsSlice = createSlice({
  name: 'customerDoctors',
  initialState: {
    custDoctorsPopData: [],
    custDoctorsNearbyData: [],
    custDoctorsDetailsData: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustDoctorsPop.fulfilled, (state, action) => {
        state.custDoctorsPopData = action.payload
      })
      .addCase(getCustDoctorNearBy.fulfilled, (state, action) => {
        state.custDoctorsNearbyData = action.payload
      })
      .addCase(getCustDoctorsDetails.fulfilled, (state, action) => {
        state.custDoctorsDetailsData = action.payload
      })
  }
})

export const {} = customerDoctorsSlice.actions
export default customerDoctorsSlice.reducer
