import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// First, define the reducer and action creators via `createSlice`
export const resturantSlice = createSlice({
  name: 'resturant',
  initialState: {
    service: [],
    service_item: [],
    serviceData: {},
    routeDataFood: {},
    routeDataMenu: {}
  },
  reducers: {
    updateServiceItem(state, action) {
      // Use a “state machine” approach for loading state instead of booleans
      state.service_item = action.payload
    },
    updateService(state, action) {
      // Use a “state machine” approach for loading state instead of booleans
      state.service = action.payload
    },
    serviceItemData(state, action) {
      state.service_item = [...state.service_item, ...action.payload]
    },
    clearServiceItemData(state, action) {
      state.service_item = []
    },
    createServiceData(state, action) {
      state.serviceData = action.payload
    },
    createRouteFoodData(state, action) {
      console.log(action.payload)
      state.routeDataFood = action.payload
    },
    clearRouteFoodData(state) {
      state.routeDataFood = {}
    },
    createRouteMenuData(state, action) {
      state.routeDataMenu = action.payload
    },
    clearRouteMenuData(state) {
      state.routeDataMenu = {}
    },
    resturantLogout(state) {
      state.service = []
      state.service_item = []
    }
  }
})
// // Action creators are generated for each case reducer function
// Destructure and export the plain action creators
export const {
  updateServiceItem,
  updateService,
  resturantLogout,
  serviceItemData,
  clearServiceItemData,
  createServiceData,
  clearRouteFoodData,
  createRouteFoodData,
  createRouteMenuData,
  clearRouteMenuData
} = resturantSlice.actions
// Define a thunk that dispatches those action creators
export const createServiceItemData = (arg) => async (dispatch) => {
  dispatch(serviceItemData(arg))
}
export const updateServiceItemData = (arg) => async (dispatch) => {
  dispatch(updateServiceItem(arg))
}
export const updateServiceDataItem = (arg) => async (dispatch) => {
  dispatch(updateService(arg))
}

export default resturantSlice.reducer
