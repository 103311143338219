// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import chat from '@src/views/apps/chat/store'
import userLoginSlice from './api'
import usersProfile from '../views/profile/store'
import businessService from '@src/views/services/business/store'
import resturantSlice from '../views/services/business/restaurants/store'
import businessTables from '../views/dashboard/business/tables/store'

// ** customer services
// import customerServices from '../views/services/personal/store'
import custAttorneyServices from '../views/services/personal/attorney/store'
import custBuySellServices from '../views/services/personal/buysell/store'
import custCarpoolService from '../views/services/personal/carpool/store'
import custJobsService from '../views/services/personal/jobs/store'
import custTravelService from '../views/services/personal/travel/store'
import custDoctorsService from '../views/services/personal/doctors/store'
import custTaxesService from '../views/services/personal/taxes/store'
import custTutorsService from '../views/services/personal/tutors/store'
import custPhotographyService from '../views/services/personal/photography/store'
import custRealEstateService from '../views/services/personal/real_estate/store'
import custRestaurantsService from '../views/services/personal/restaurants/store'
import custCulturalService from '../views/services/personal/cultural/store'
import custGroceryService from '../views/services/personal/grocery/store'
import custUniversityService from '../views/services/personal/universityInfo/store'
import custCleanerService from '../views/services/personal/cleaner/store'
import custHandymanService from '../views/services/personal/handyman/store'

// ** ecommerce
import checkout from '../views/services/personal/checkout/store'
import ecommerce from '../views/services/shop/store'

const rootReducer = {
  auth,
  navbar,
  layout,
  chat,
  userLoginSlice,
  usersProfile,
  // ** ecommerce
  ecommerce,
  checkout,
  // ** business slices
  businessService,
  resturantSlice,
  businessTables,
  // ** customer slices
  custAttorneyServices,
  custBuySellServices,
  custCarpoolService,
  custJobsService,
  custTravelService,
  custDoctorsService,
  custTaxesService,
  custTutorsService,
  custPhotographyService,
  custRealEstateService,
  custRestaurantsService,
  custCulturalService,
  custGroceryService,
  custUniversityService,
  custCleanerService,
  custHandymanService
  // customerServices
}

export default rootReducer
