// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiHit } from '../../../../../Api/apiRequest'
import { setStatus, STATUSES } from '../../../../../redux/authentication'

//get customer service data
export const getCustCarpoolService = createAsyncThunk(
  'services/customer/getCustCarpoolService',
  async (data, { dispatch }) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

//get customer carpool explore
export const getCustCarpoolDetails = createAsyncThunk(
  'services/customer/getCustCarpoolDetails',
  async (data, { dispatch }) => {
    console.log(data)
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data[0]
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

export const customerCarpoolSlice = createSlice({
  name: 'customerCarpool',
  initialState: {
    custPopCarpoolData: [],
    custCarpoolDetailsData: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustCarpoolService.fulfilled, (state, action) => {
        state.custPopCarpoolData = action.payload
      })
      .addCase(getCustCarpoolDetails.fulfilled, (state, action) => {
        state.custCarpoolDetailsData = action.payload
      })
  }
})

export const {} = customerCarpoolSlice.actions
export default customerCarpoolSlice.reducer
