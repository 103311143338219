// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiHit } from '../../../../../Api/apiRequest'
import { setStatus, STATUSES } from '../../../../../redux/authentication'

//get customer popular tutors
export const getCustPopTutors = createAsyncThunk('services/customer/getCustPopTutors', async (data, { dispatch }) => {
  const body = new FormData()
  Object.keys(data).forEach((key) => {
    body.append(key, data[key])
  })

  const response = await apiHit(body, dispatch)
    .then(async (res) => {
      console.log(res)
      if (res.data.length !== 0) {
        dispatch(setStatus(STATUSES.IDLE))
        return res.data.data
      } else {
        dispatch(setStatus(STATUSES.IDLE))
        return res.data
      }
    })
    .catch((err) => {
      dispatch(setStatus(STATUSES.ERROR))
      return err
    })
  return response
})

// ** get near by tutors
export const getCustTutorsNearBy = createAsyncThunk(
  'services/customer/getCustTutorsNearBy',
  async (data, { dispatch }) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

//get customer tutors explore
export const getCustExploreTutors = createAsyncThunk(
  'services/customer/getCustExploreTutors',
  async (data, { dispatch }) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

//get customer tutors explore
export const getCustTutorsDetails = createAsyncThunk(
  'services/customer/getCustTutorsDetails',
  async (data, { dispatch }) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data[0]
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

export const customerTutorsSlice = createSlice({
  name: 'customerTutors',
  initialState: {
    custPopTutorsData: [],
    custNearbyTutorsData: [],
    custExploreTutorsData: [],
    custTutorDetailsData: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustPopTutors.fulfilled, (state, action) => {
        state.custPopTutorsData = action.payload
      })
      .addCase(getCustTutorsNearBy.fulfilled, (state, action) => {
        state.custNearbyTutorsData = action.payload
      })
      .addCase(getCustExploreTutors.fulfilled, (state, action) => {
        state.custExploreTutorsData = action.payload
      })
      .addCase(getCustTutorsDetails.fulfilled, (state, action) => {
        state.custTutorDetailsData = action.payload
      })
  }
})

// export const {} = customerTutorsSlice.actions
export default customerTutorsSlice.reducer
