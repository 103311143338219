// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiHit } from '../../../../Api/apiRequest'
import { setStatus, STATUSES } from '../../../../redux/authentication'

// export const addToCart = createAsyncThunk(
//   'appEcommerce/addToCart',
//   async (id, { dispatch }) => {
//     return
//   }
// )

//get local link service fee
export const getServiceCharges = createAsyncThunk('appEcommerce/getServiceCharges', async (data, { dispatch }) => {
  const body = new FormData()
  body.append('type', 'get_data')
  body.append('table_name', 'service_fee')

  const response = await apiHit(body, dispatch)
    .then(async (res) => {
      console.log(res)
      if (res.data.length !== 0) {
        dispatch(setStatus(STATUSES.IDLE))
        return res.data.data
      } else {
        dispatch(setStatus(STATUSES.IDLE))
        return res.data
      }
    })
    .catch((err) => {
      dispatch(setStatus(STATUSES.ERROR))
      return err
    })
  return response
})

export const checkBusinessCart = createAsyncThunk(
  'appEcommerce/checkBusinessCart',
  async (params, { dispatch, getState }) => {
    const cart = await getState().ecommerce.cart
    if (cart?.length === 0 || params.user_id === cart[0]?.user_id) {
      dispatch(addToCart(params))
    } else {
      dispatch(removeCart())
      dispatch(addToCart(params))
    }
  }
)

export const cartItemQuantity = createAsyncThunk(
  'appEcommerce/cartItemQuantity',
  async (params, { dispatch, getState }) => {
    const cart = getState().ecommerce.cart
    const existingItemIndex = cart.findIndex((item) => item.cartId === params.cartId)
    if (existingItemIndex >= 0) {
      // if item already exist so, increment quantity
      const updatedCartItems = [...cart]
      updatedCartItems[existingItemIndex] = {
        ...updatedCartItems[existingItemIndex],
        quantity: params.quantity,
        totalPrice: updatedCartItems[existingItemIndex].price * params.quantity
      }
      dispatch(cartItemsTotal())
      return updatedCartItems
    }
  }
)

export const addOrderPlaced = createAsyncThunk(
  'appEcommerce/addOrderPlaced',
  async (params, { dispatch, getState }) => {
    console.log(params)
  }
)

const initialCart = () => {
  const item = window.localStorage.getItem('cartItems')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : []
}

export const appEcommerceSlice = createSlice({
  name: 'appEcommerce',
  initialState: {
    cart: initialCart(),
    cartTotal: 0,
    serviceChargesData: []
    // params: {},
    // products: [],
    // wishlist: [],
    // totalProducts: 0,
    // productDetail: {}
  },
  reducers: {
    addToCart: (state, action) => {
      const newItem = action.payload
      const cart = state.cart

      const existingItemIndex = cart.findIndex((item) => {
        // Get all the keys of newItem || Note : newItem does't contain quantity, totalPrice and cartId
        const newItemKeys = Object.keys(newItem)?.filter((item) => item !== 'cartId')
        // Check if all key-value pairs in newItem are present in the current item rather than quantity, totalPrice
        return newItemKeys.every((key) => item[key] === newItem[key])
      })
      if (existingItemIndex >= 0) {
        // if item already exists in cart, increment quantity and update total price
        const updatedCartItems = [...cart]
        updatedCartItems[existingItemIndex] = {
          ...updatedCartItems[existingItemIndex],
          quantity: updatedCartItems[existingItemIndex].quantity + 1,
          totalPrice: updatedCartItems[existingItemIndex].totalPrice + newItem.price
        }
        state.cart = updatedCartItems
        localStorage.setItem('cartItems', JSON.stringify(updatedCartItems))
      } else {
        // if item doesn't exist in cart, add new item with quantity and total price
        const updatedCartItems = [
          ...cart,
          {
            ...newItem,
            quantity: 1,
            totalPrice: newItem.price
          }
        ]
        state.cart = updatedCartItems
        localStorage.setItem('cartItems', JSON.stringify(updatedCartItems))
      }
    },
    cartItemsTotal: (state, action) => {
      let total = 0
      action.payload?.forEach((list) => {
        total += Number(list.price * list.quantity)
      })
      state.cartTotal = total
    },

    removeCartItems: (state, action) => {
      const cart = initialCart()

      // remove item from cart by id
      const removedItem = cart.find((item) => item.id === action.payload.id)
      if (removedItem) {
        state.cart = cart.filter((item) => item.id !== action.payload.id)
        localStorage.setItem('cartItems', JSON.stringify(state.cart))
      }
    },
    removeCart: (state, action) => {
      state.cart = []
      localStorage.removeItem('cartItems')
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(cartItemQuantity.fulfilled, (state, action) => {
        state.cart = action.payload
        localStorage.setItem('cartItems', JSON.stringify(action.payload))
      })
      .addCase(getServiceCharges.fulfilled, (state, action) => {
        state.serviceChargesData = action.payload
      })
  }
})

export const {
  addToCart,
  removeCart,
  cartItemsTotal,
  removeCartItems
  // cartItemQuantity
} = appEcommerceSlice.actions

export default appEcommerceSlice.reducer
