// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiHit } from '../../../../../Api/apiRequest'
import { setStatus, STATUSES } from '../../../../../redux/authentication'

//get customer service data
export const getCustTaxesService = createAsyncThunk(
  'services/customer/getCustTaxesService',
  async (data, { dispatch }) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

//get customer service data || nearby place
export const getCustTaxesNearBy = createAsyncThunk(
  'services/customer/getCustTaxesNearBy',
  async (data, { dispatch }) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

//get customer taxes explore
export const getCustTaxesDetails = createAsyncThunk(
  'services/customer/getCustTaxesDetails',
  async (data, { dispatch }) => {
    console.log(data)
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data[0]
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

export const customerTaxesSlice = createSlice({
  name: 'customerTaxes',
  initialState: {
    custTaxesData: [],
    custNearbyTaxesData: [],
    custTaxesDetailsData: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustTaxesService.fulfilled, (state, action) => {
        state.custTaxesData = action.payload
      })
      .addCase(getCustTaxesNearBy.fulfilled, (state, action) => {
        state.custNearbyTaxesData = action.payload
      })
      .addCase(getCustTaxesDetails.fulfilled, (state, action) => {
        state.custTaxesDetailsData = action.payload
      })
  }
})

export const {} = customerTaxesSlice.actions
export default customerTaxesSlice.reducer
