// ** Redux Imports
import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiHit } from '../../../../../Api/apiRequest'
import { setStatus, STATUSES } from '../../../../../redux/authentication'
import { removeCart } from '../../../shop/store'

//get customer service data
export const addOrder = createAsyncThunk('services/customer/addOrder', async (data, { dispatch }) => {
  const body = new FormData()
  body.append('type', 'add_data')
  body.append('table_name', 'orders')
  Object.keys(data).forEach((key) => {
    body.append(key, data[key])
  })

  const response = await apiHit(body, dispatch)
    .then(async (res) => {
      console.log(res)
      if (res.data.result === true) {
        dispatch(setStatus(STATUSES.IDLE))
        dispatch(removeCart())
        return res.data.message
      } else {
        dispatch(setStatus(STATUSES.IDLE))
        return res.data
      }
    })
    .catch((err) => {
      dispatch(setStatus(STATUSES.ERROR))
      return err
    })
  return response
})
