// ** service role
export const serviceRole = {
  attorney: 'multi',
  carpool: 'single',
  doctors: 'multi',
  photography: 'multi',
  restaurants: 'single',
  tutors: 'single',
  taxes: 'multi',
  buy_sell: 'multi',
  cultural: 'multi',
  grocery: 'multi',
  jobs: 'multi',
  travel: 'multi',
  real_estate: 'multi',
  university: 'multi',
  cleaner: 'multi',
  handyman: 'multi'
}

export const businessServices = [
  {
    name: 'Buy/Sell',
    type: 'buy_sell',
    id: 1,
    link: 'CreateBusinessService'
  },
  {
    name: 'Jobs',
    type: 'jobs',
    id: 2,
    link: 'JobInfo'
  },
  {
    name: 'Travel',
    type: 'travel',
    id: 3,
    link: 'TravelCreateService'
  },
  {
    name: 'Doctors',
    type: 'doctors',
    id: 4,
    link: 'GeneralInfo'
  },
  {
    name: 'Attorney',
    type: 'attorney',
    id: 5,
    link: 'AttorneyGeneralInfo'
  },
  {
    name: 'Taxes',
    type: 'taxes',
    id: 6,
    link: 'CreateTaxService'
  },
  {
    name: 'Tutors',
    type: 'tutors',
    id: 7,
    link: 'TutorGeneralInfo'
  },
  {
    name: 'Photography',
    type: 'photography',
    id: 8,
    link: 'PhotoGeneralInfo'
  },
  {
    name: 'Real Estate',
    type: 'real_estate',
    id: 9,
    link: 'RealEstateCreateService'
  },
  {
    name: 'Restaurants',
    type: 'restaurants',
    id: 10,
    link: 'RestaurantGeneralInfo'
  },
  {
    name: 'Cultural',
    type: 'cultural',
    id: 11,
    link: 'CulturalGeneralInfo'
  },
  {
    name: 'Grocery',
    type: 'grocery',
    id: 12,
    link: 'GrocerGeneralInfo'
  },
  {
    name: 'Carpool',
    type: 'carpool',
    id: 13,
    link: 'CarpoolGeneralInfo'
  },
  {
    name: 'University information',
    type: 'university',
    id: 14,
    link: 'CreateBusinessService'
  },
  {
    name: 'Cleaner',
    type: 'cleaner',
    id: 15,
    link: 'CreateBusinessService'
  },
  {
    name: 'Handyman',
    type: 'handyman',
    id: 16,
    link: 'CreateBusinessService'
  }
]
