// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiHit } from '../../../../../Api/apiRequest'
import { setStatus, STATUSES } from '../../../../../redux/authentication'

//get customer service data
// export const getCustTravelService = createAsyncThunk(
//   'services/customer/getCustTravelService',
//   async (data, { dispatch }) => {
//     const body = new FormData()
//     Object.keys(data).forEach((key) => {
//       body.append(key, data[key])
//     })

//     const response = await apiHit(body, dispatch)
//       .then(async (res) => {
//         console.log(res)
//         if (res.data.length !== 0) {
//           dispatch(setStatus(STATUSES.IDLE))
//           return res.data.data
//         } else {
//           dispatch(setStatus(STATUSES.IDLE))
//           return res.data
//         }
//       })
//       .catch((err) => {
//         dispatch(setStatus(STATUSES.ERROR))
//         return err
//       })
//     return response
//   }
// )

//get customer service data || popular place
export const getCustTravelPop = createAsyncThunk('services/customer/getCustTravelPop', async (data, { dispatch }) => {
  const body = new FormData()
  Object.keys(data).forEach((key) => {
    body.append(key, data[key])
  })

  const response = await apiHit(body, dispatch)
    .then(async (res) => {
      console.log(res)
      if (res.data.length !== 0) {
        dispatch(setStatus(STATUSES.IDLE))
        return res.data.data
      } else {
        dispatch(setStatus(STATUSES.IDLE))
        return res.data
      }
    })
    .catch((err) => {
      dispatch(setStatus(STATUSES.ERROR))
      return err
    })
  return response
})

//get customer service data || near by
export const getCustTravelNearBy = createAsyncThunk(
  'services/customer/getCustTravelNearBy',
  async (data, { dispatch }) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

//get customer cuntries data
export const getCustTravelCountries = createAsyncThunk(
  'services/customer/getCustTravelCountries',
  async (data, { dispatch }) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

//get customer travel explore
export const getCustTravelDetails = createAsyncThunk(
  'services/customer/getCustTravelDetails',
  async (data, { dispatch }) => {
    console.log(data)
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data[0]
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

//get customer buy_sell Filters Result data
export const getCustTravelFiltersData = createAsyncThunk(
  'services/customer/getCustTravelFiltersData',
  async (data, { dispatch }) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

export const customerTravelSlice = createSlice({
  name: 'customerTravel',
  initialState: {
    custTravelPop: [],
    custTravelNearByData: [],
    custTravelCountryData: [],
    cusTravelDetailsData: [],
    custTravelFiltersResData: ''
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustTravelPop.fulfilled, (state, action) => {
        state.custTravelPop = action.payload
      })
      .addCase(getCustTravelNearBy.fulfilled, (state, action) => {
        state.custTravelNearByData = action.payload
      })
      .addCase(getCustTravelCountries.fulfilled, (state, action) => {
        state.custTravelCountryData = action.payload
      })
      .addCase(getCustTravelDetails.fulfilled, (state, action) => {
        state.cusTravelDetailsData = action.payload
      })
      .addCase(getCustTravelFiltersData.fulfilled, (state, action) => {
        state.custTravelFiltersResData = action.payload
      })
  }
})

// export const {} = customerTravelSlice.actions
export default customerTravelSlice.reducer
