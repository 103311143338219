// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import authService, { apiHit } from '../../../Api/apiRequest'

export const getProfileData = createAsyncThunk('auth/register', async (obj, { dispatch, getState }) => {
  const body = new FormData()
  const userData = getState().auth.userData
  body.append('type', 'profile')
  body.append('user_id', userData.user_id)
  return await apiHit({ body, dispatch }).then(async (res) => {
    return res.profile
  })
})

export const usersProfileSlice = createSlice({
  name: 'UsersProfile',
  initialState: {
    // data: [],
    // total: 1,
    // params: {},
    userProfileData: []
    // selectedUser: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProfileData.fulfilled, (state, action) => {
      state.userProfileData = action.payload
    })
    // .addCase(getData.fulfilled, (state, action) => {
    //   state.data = action.payload.data
    //   state.params = action.payload.params
    //   state.total = action.payload.totalPages
    // })
    // .addCase(getUser.fulfilled, (state, action) => {
    //   state.selectedUser = action.payload
    // })
  }
})
export default usersProfileSlice.reducer
