// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

const ApiUrl = 'https://7tracking.com/locallinq/api.php'

// export async function dataGet({ body }) {
//   return await axios
//     .post(ApiUrl, body, {
//       headers: { 'Content-Type': 'multipart/form-data' }
//     })
//     .then((res) => {
//       return res.data
//     })
//     .catch((err) => {
//       return err
//     })
// }
// export const STATUSES = Object.freeze({
//   IDLE: 'idle',
//   ERRORL: 'error',
//   LOADING: 'loading'
// })

// ** thunk
export const signupUser = createAsyncThunk('users/signupUser', async (body) => {
  return await axios
    .post(ApiUrl, body, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then((res) => {
      console.log('data', data)
      return res.data
    })
    .catch((err) => {
      return err
    })
})

export const userLoginSlice = createSlice({
  name: 'userLogin',
  initialState: {
    // data: [],
    // status: STATUSES.IDLE
    loading: false,
    userInfo: null,
    userToken: null,
    error: null,
    success: false
  },
  // reducers: {
  //   add(state, action) {
  //     state.push(action.payload)
  //   },
  //   remove(state, action) {
  //     state.filter((item) => item.id !== action.payload)
  //   }
  // }

  extraReducers: {
    [signupUser.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [signupUser.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true // registration successful
    },
    [signupUser.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    }
    // builder
    //   .addCase(signupUser.pending, (state, action) => {
    //     // state.status = STATUSES.LOADING
    //     state.isFetching = true
    //   })
    //   .addCase(signupUser.fulfilled, (state, action) => {
    //     state.data = action.payload
    //     // state.status = STATUSES.IDLE
    //     state.isFetching = false
    //     state.isSuccess = true
    //   })
    //   .addCase(signupUser.rejected, (state, action) => {
    //     // state.status = STATUSES.ERROR
    //     state.isFetching = false
    //     state.isError = true
    //   })
  }
})

export default userLoginSlice.reducer
