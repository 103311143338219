// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiHit } from '../../../../../Api/apiRequest'
import { setStatus, STATUSES } from '../../../../../redux/authentication'

//get popular jobs service data
export const getCustPopJobsService = createAsyncThunk(
  'services/customer/getCustPopJobsService',
  async (data, { dispatch }) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

//get popular jobs service data
export const getCustNearByJobsService = createAsyncThunk(
  'services/customer/getCustNearByJobsService',
  async (data, { dispatch }) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

//get customer jobs explore
export const getCustJobsDetails = createAsyncThunk(
  'services/customer/getCustJobsDetails',
  async (data, { dispatch }) => {
    console.log(data)
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data[0]
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

//get customer buy_sell Filters
export const getCustJobsFilters = createAsyncThunk(
  'services/customer/getCustJobsFilters',
  async (data, { dispatch }) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)
//get customer buy_sell Filters Result data
export const getCustJobsFiltersData = createAsyncThunk(
  'services/customer/getCustJobsFiltersData',
  async (data, { dispatch }) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

export const addJobOrder = createAsyncThunk('services/customer/addJobOrder', async (params, { dispatch, getState }) => {
  const userData = getState().auth.userData
  const data = {
    type: 'add_data',
    table_name: 'orders',
    user_id: userData?.user_id,
    // address: userData?.address,
    ...params
  }
  console.log(data)
  const body = new FormData()
  Object.keys(data).forEach((key) => {
    body.append(key, data[key])
  })

  const response = await apiHit(body, dispatch)
    .then(async (res) => {
      console.log(res)
      if (res.data.result === true) {
        dispatch(setStatus(STATUSES.IDLE))
        // return res.data.data
      } else {
        dispatch(setStatus(STATUSES.IDLE))
        // return res.data
      }
    })
    .catch((err) => {
      dispatch(setStatus(STATUSES.ERROR))
      return err
    })
  return response
})

export const customerJobsSlice = createSlice({
  name: 'customerJobs',
  initialState: {
    custPopJobsData: [],
    custJobsNearByData: [],
    custJobsDetailsData: [],
    custJobsFilter: [],
    custJobsFiltersResData: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustPopJobsService.fulfilled, (state, action) => {
        state.custPopJobsData = action.payload
      })
      .addCase(getCustNearByJobsService.fulfilled, (state, action) => {
        state.custJobsNearByData = action.payload
      })
      .addCase(getCustJobsDetails.fulfilled, (state, action) => {
        state.custJobsDetailsData = action.payload
      })
      .addCase(getCustJobsFilters.fulfilled, (state, action) => {
        state.custJobsFilter = action.payload
      })
      .addCase(getCustJobsFiltersData.fulfilled, (state, action) => {
        state.custJobsFiltersResData = action.payload
      })
  }
})

export const {} = customerJobsSlice.actions
export default customerJobsSlice.reducer
