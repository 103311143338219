// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiHit } from '../../../../../Api/apiRequest'
import { setStatus, STATUSES } from '../../../../../redux/authentication'

//get customer popular attorney
export const getCustPopAttorney = createAsyncThunk(
  'services/customer/getCustPopAttorney',
  async (data, { dispatch }) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

// ** get near by attorney
export const getCustAttorneyNearBy = createAsyncThunk(
  'services/customer/getCustAttorneyNearBy',
  async (data, { dispatch }) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

//get customer attorney explore
export const getCustAttorneyDetails = createAsyncThunk(
  'services/customer/getCustAttorneyDetails',
  async (data, { dispatch }) => {
    console.log(data)
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data[0]
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

export const customerAttorneySlice = createSlice({
  name: 'customerAttorney',
  initialState: {
    custPopAttorneyData: [],
    custNearByAttorneyData: [],
    custAttorneyDetailsData: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustPopAttorney.fulfilled, (state, action) => {
        state.custPopAttorneyData = action.payload
      })
      .addCase(getCustAttorneyNearBy.fulfilled, (state, action) => {
        state.custNearByAttorneyData = action.payload
      })
      .addCase(getCustAttorneyDetails.fulfilled, (state, action) => {
        state.custAttorneyDetailsData = action.payload
      })
  }
})

export const {} = customerAttorneySlice.actions
export default customerAttorneySlice.reducer
