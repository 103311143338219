// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiHit } from '../../../../../Api/apiRequest'
import { setStatus, STATUSES } from '../../../../../redux/authentication'

//get customer service data
export const getCustCleanerService = createAsyncThunk(
  'services/customer/getCustCleanerService',
  async (data, { dispatch }) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

//get customer service data || popular place
export const getCustCleanerNearBy = createAsyncThunk(
  'services/customer/getCustCleanerNearBy',
  async (data, { dispatch }) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

//get customer Cleaner explore
export const getCustCleanerDetails = createAsyncThunk(
  'services/customer/getCustCleanerDetails',
  async (data, { dispatch }) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data[0]
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

export const customerCleanerSlice = createSlice({
  name: 'customerCleaner',
  initialState: {
    custPopCleanerData: [],
    custNearbyCleaner: [],
    custCleanerDetailsData: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustCleanerService.fulfilled, (state, action) => {
        state.custPopCleanerData = action.payload
      })
      .addCase(getCustCleanerNearBy.fulfilled, (state, action) => {
        state.custNearbyCleaner = action.payload
      })
      .addCase(getCustCleanerDetails.fulfilled, (state, action) => {
        state.custCleanerDetailsData = action.payload
      })
  }
})

export const {} = customerCleanerSlice.actions
export default customerCleanerSlice.reducer
