import axios from 'axios'
import { setStatus, STATUSES } from '../redux/authentication'

const Headers = {
  Header: {
    'Content-Type': 'application/json'
  },
  Header2: {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data'
  }
}

export const body = new FormData()

// ** base Url
const API_URL = 'https://7tracking.com/locallinq/api.php'
export const BaseUrl = 'https://local-linq.com/'

// ** image url
export const imgURL = 'https://7tracking.com/locallinq/images/'

export const defaultImg = require(`@src/assets/images/avatars/avatar-default.png`).default
export const apiHit = async (body, dispatch) => {
  dispatch(setStatus(STATUSES.LOADING))
  return await axios.post(API_URL, body, {
    headers: Headers.Header2
  })
}

const register = async ({ body }) => {
  return await axios
    .post(API_URL, body, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      return err
    })
}

export const apiHitSecond = async (body) => {
  return await axios.post(API_URL, body, {
    headers: Headers.Header2
  })
}

// ** login user
// const register = async (userData) => {
//   const response = await axios.post(API_URL, userData, {
//     headers: { 'Content-Type': 'multipart/form-data' }
//   })
//   if (response['result'] === true) {
//     console.log(response.data)
//   }
//   return response.data
// }
const authService = { register }
export default authService
