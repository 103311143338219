// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiHit } from '../../../../../Api/apiRequest'
import { setStatus, STATUSES } from '../../../../../redux/authentication'

//get customer service data
export const getCustPhotographyService = createAsyncThunk(
  'services/customer/getCustPhotographyService',
  async (data, { dispatch }) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

//get customer service data || popular place
export const getCustPhotographyNearBy = createAsyncThunk(
  'services/customer/getCustPhotographyNearBy',
  async (data, { dispatch }) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

//get customer photography explore
export const getCustPhotographyDetails = createAsyncThunk(
  'services/customer/getCustPhotographyDetails',
  async (data, { dispatch }) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data[0]
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

export const customerPhotographySlice = createSlice({
  name: 'customerPhotography',
  initialState: {
    custPopPhotographyData: [],
    custNearbyPhotography: [],
    custPhotographyDetailsData: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustPhotographyService.fulfilled, (state, action) => {
        state.custPopPhotographyData = action.payload
      })
      .addCase(getCustPhotographyNearBy.fulfilled, (state, action) => {
        state.custNearbyPhotography = action.payload
      })
      .addCase(getCustPhotographyDetails.fulfilled, (state, action) => {
        state.custPhotographyDetailsData = action.payload
      })
  }
})

export const {} = customerPhotographySlice.actions
export default customerPhotographySlice.reducer
