// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { apiHit } from '../../../../Api/apiRequest'
import { setStatus, STATUSES } from '../../../../redux/authentication'

export const getUserProfile = createAsyncThunk('appChat/getTasks', async () => {
  const response = await axios.get('/apps/chat/users/profile-user')
  return response.data
})

export const getChatContacts = createAsyncThunk('appChat/getChatContacts', async () => {
  const response = await axios.get('/apps/chat/chats-and-contacts')
  return response.data
})

// export const selectChat = createAsyncThunk('appChat/selectChat', async (id, { dispatch }) => {
//   const response = await axios.get('/apps/chat/get-chat', { id })
//   await dispatch(getChatContacts())
//   console.log(response.data)
//   return response.data
// })

export const sendMsg = createAsyncThunk('appChat/sendMsg', async (obj, { dispatch }) => {
  const response = await axios.post('/apps/chat/send-msg', { obj })
  await dispatch(selectChat(obj.contact.id))
  return response.data
})

// export const sendMessage = createAsyncThunk(
//   'appChat/sendMessage',
//   async (obj, { dispatch }) => {
//     const body = new FormData()
//     Object.keys(data).forEach((key) => {
//       body.append(key, data[key])
//     })
//     const response = await apiHit(body, dispatch)
//       .then(async (res) => {
//         console.log(res)
//         if (res.data.length !== 0) {
//           await dispatch(setStatus(STATUSES.IDLE))
//           return res.data.data
//         } else {
//           await dispatch(setStatus(STATUSES.IDLE))
//           return res.data
//         }
//       })
//       .catch((err) => {
//         dispatch(setStatus(STATUSES.ERROR))
//         return err
//       })
//     return response
//   }
// )

// ** chat list

export const chatList = createAsyncThunk('appChat/chatList', async (data, { dispatch }) => {
  const body = new FormData()
  body.append('type', 'chat_list')
  body.append('user_id', data.user_id)
  const response = await apiHit(body, dispatch)
    .then(async (res) => {
      console.log(res)
      if (res.data.length !== 0) {
        await dispatch(setStatus(STATUSES.IDLE))
        return res.data.chat
      } else {
        await dispatch(setStatus(STATUSES.IDLE))
        return res.data
      }
    })
    .catch((err) => {
      dispatch(setStatus(STATUSES.ERROR))
      return err
    })
  return response
})
export const getChat = createAsyncThunk('appChat/getChat', async (obj, { dispatch, getState }) => {
  const body = new FormData()
  const userData = getState().auth.userData
  body.append('type', 'getchat')
  // body.append('user_id', 'JfnvgQKQ3BiDZBRveR8uY4DB6DRYd1Hv')
  body.append('user_id', userData.user_id)
  body.append('to_chat_id', obj.userId)
  const response = await apiHit(body, dispatch)
    .then(async (res) => {
      console.log(res)
      if (res.data.length !== 0) {
        await dispatch(setStatus(STATUSES.IDLE))
        return res.data.chat
      } else {
        await dispatch(setStatus(STATUSES.IDLE))
        return res.data
      }
    })
    .catch((err) => {
      dispatch(setStatus(STATUSES.ERROR))
      return err
    })
  return response
})
export const sendMessage = createAsyncThunk('appChat/sendMessage', async (data, { dispatch, getState }) => {
  const userData = getState().auth.userData
  const body = new FormData()
  body.append('type', 'sendmsg')
  body.append('user_id', userData.user_id)
  body.append('to_chat_id', data?.userId)
  body.append('msg', data?.msg)

  console.log(data)
  const response = await apiHit(body, dispatch)
    .then(async (res) => {
      console.log(res)
      if (res.data.length !== 0) {
        // await dispatch(getChat())
        await dispatch(setStatus(STATUSES.IDLE))
        return res.data.chat
      } else {
        await dispatch(setStatus(STATUSES.IDLE))
        return res.data
      }
    })
    .catch((err) => {
      dispatch(setStatus(STATUSES.ERROR))
      return err
    })
  return response
})

export const checkMsg = createAsyncThunk('appChat/checkMsg', async (data, { dispatch, getState }) => {
  const userData = getState().auth.userData
  const body = new FormData()
  body.append('type', 'checkmsg')
  body.append('user_id', userData.user_id)
  body.append('to_chat_id', data?.userId)
  body.append('timestamp', data?.timestamp)

  const response = await apiHit(body, dispatch)
    .then(async (res) => {
      console.log(res)
      if (res.data.length !== 0) {
        const newMessage = res?.data?.chat[0]
        const obj = { newMessage1: newMessage, userData1: userData }
        await dispatch(setStatus(STATUSES.IDLE))
        // if (res.data?.chat[0]?.sender_token !== userData.user_id) {
        //   return {res.data.chat[0]}
        // }
        return obj
      } else {
        await dispatch(setStatus(STATUSES.IDLE))
        return null
      }
    })
    .catch((err) => {
      dispatch(setStatus(STATUSES.ERROR))
      return err
    })
  return response
})

export const appChatSlice = createSlice({
  name: 'appChat',
  initialState: {
    chats: [],
    contacts: [],
    userProfile: {},
    selectedUser: {},
    chatListUser: [],
    userChats: []
    // newMessage: {}
  },
  reducers: {
    selectChat: (state, action) => {
      state.selectedUser = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.userProfile = action.payload
      })
      .addCase(getChatContacts.fulfilled, (state, action) => {
        state.chats = action.payload.chatsContacts
        state.contacts = action.payload.contacts
      })
      // .addCase(selectChat.fulfilled, (state, action) => {
      //   state.selectedUser = action.payload
      // })
      .addCase(chatList.fulfilled, (state, action) => {
        state.chatListUser = action.payload
      })
      .addCase(getChat.fulfilled, (state, action) => {
        state.userChats = action.payload
      })
      .addCase(checkMsg.fulfilled, (state, action) => {
        if (action.payload !== null) {
          const { newMessage1, userData1 } = action.payload

          if (newMessage1?.sender_token !== userData1.user_id) {
            state.userChats = [...state.userChats, newMessage1]
          } else {
            const data = state.userChats
            // data.splice(-1)
            state.userChats = [...data, newMessage1]
          }
        }
      })
  }
})

export const { selectChat } = appChatSlice.actions

export default appChatSlice.reducer
