// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiHit } from '../../../../../Api/apiRequest'
import { setStatus, STATUSES } from '../../../../../redux/authentication'

//get customer popular place
export const getCustPopRealEstate = createAsyncThunk(
  'services/customer/getCustPopRealEstate',
  async (data, { dispatch }) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

//get customer nearby place
export const getCustRealEstateNearby = createAsyncThunk(
  'services/customer/getCustRealEstateNearby',
  async (data, { dispatch }) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

//get customer real_estate explore
export const getCustRealEstateDetails = createAsyncThunk(
  'services/customer/getCustRealEstateDetails',
  async (data, { dispatch }) => {
    console.log(data)
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data[0]
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

//get customer real_estate Filters Result data
export const getCustRealEstateFiltersData = createAsyncThunk(
  'services/customer/getCustRealEstateFiltersData',
  async (data, { dispatch }) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      body.append(key, data[key])
    })

    const response = await apiHit(body, dispatch)
      .then(async (res) => {
        console.log(res)
        if (res.data.length !== 0) {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data.data
        } else {
          dispatch(setStatus(STATUSES.IDLE))
          return res.data
        }
      })
      .catch((err) => {
        dispatch(setStatus(STATUSES.ERROR))
        return err
      })
    return response
  }
)

export const customerRealEstateSlice = createSlice({
  name: 'customerRealEstate',
  initialState: {
    custPopRealEstateData: [],
    custRealEstateNearbyData: [],
    custRealEstateDetailsData: [],
    custRealEstateFiltersResData: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustPopRealEstate.fulfilled, (state, action) => {
        state.custPopRealEstateData = action.payload
      })
      .addCase(getCustRealEstateNearby.fulfilled, (state, action) => {
        state.custRealEstateNearbyData = action.payload
      })
      .addCase(getCustRealEstateDetails.fulfilled, (state, action) => {
        state.custRealEstateDetailsData = action.payload
      })
      .addCase(getCustRealEstateFiltersData.fulfilled, (state, action) => {
        state.custRealEstateFiltersResData = action.payload
      })
  }
})

export const {} = customerRealEstateSlice.actions
export default customerRealEstateSlice.reducer
